export const websites = [
  'https://qa-www.tvb.com',
  'https://www.tvb.com',
  'https://homepage-4e5c5tu4dq-df.a.run.app'
]

export const adsConfig = {
  HomePage: { position: 6, unit: '/home' },
  category: { position: 7, unit: '/content' },
  subCategory: { position: 8, unit: '/content' },
  detail: { position: 9, unit: '/content' },
  search: { position: 10, unit: '/content/search' }
}