<script setup name="TvbPlayIcon">
import { inject } from 'vue'
const layoutSize = inject('size')
defineProps({
  /**
   * 大小
   * Object { xl: 0, l: 0, m: 0, s: 0, xs: 0 }
   * Number 0
   */
  size: {
    type: [Object, Number],
    default: null
  }
})
</script>

<template>
  <div
    class="tvb-play-icon"
    :class="[layoutSize]"
    :style="{
      width: size ? `${size[layoutSize] || size}px` : null,
      height: size ? `${size[layoutSize] || size}px` : null,
      fontSize: size ? `${(size[layoutSize] || size) / 2}px` : null,
      borderRadius: size ? `${(size[layoutSize] || size) / 2}px` : null
    }"
  >
    <TvbSvgIcon name="icon_play" />
  </div>
</template>

<style lang="scss" scoped>
.tvb-play-icon {
  border-radius: 48px;
  width: 48px;
  height: 48px;
  background: rgba(0, 0, 0, 0.5);
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.15s linear;
  opacity: 1;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }

  &.hide {
    transform: scale(1.2);
    opacity: 0;
    cursor: unset;
  }
}
</style>
