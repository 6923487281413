const termsRouters = [
  {
    path: "index.html",
    name: "TermsTc",
    component: () =>
      import("@/views/Terms/Index/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "tc" },
  },
  {
    path: "index_e.html",
    name: "TermsEn",
    component: () =>
      import("@/views/Terms/Index/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "en" },
  },
  {
    path: "tvb_member_terms",
    name: "TvbMemberTermsTc",
    component: () =>
      import("@/views/Terms/TvbMemberTerms/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "tc" },
  },
  {
    path: "e_tvb_member_terms",
    name: "TvbMemberTermsEn",
    component: () =>
      import("@/views/Terms/TvbMemberTerms/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "en" },
  },
  {
    path: "tvb_member_pics",
    name: "TvbMemberPicsTc",
    component: () =>
      import("@/views/Terms/TvbMemberPics/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "tc" },
  },
  {
    path: "e_tvb_member_pics",
    name: "TvbMemberPicsEn",
    component: () =>
      import("@/views/Terms/TvbMemberPics/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "en" },
  },
  {
    path: "tvb_member_eupn_overview",
    name: "TvbMemberEupnOverviewTc",
    component: () =>
      import("@/views/Terms/TvbMemberEupnOverview/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "tc" },
  },
  {
    path: "e_tvb_member_eupn_overview",
    name: "TvbMemberEupnOverviewEn",
    component: () =>
      import("@/views/Terms/TvbMemberEupnOverview/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "en" },
  },
  {
    path: "tvb_member_eupn",
    name: "TvbMemberEupnTc",
    component: () =>
      import("@/views/Terms/TvbMemberEupn/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "tc" },
  },
  {
    path: "e_tvb_member_eupn",
    name: "TvbMemberEupnEn",
    component: () =>
      import("@/views/Terms/TvbMemberEupn/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "en" },
  },
  {
    path: "tvb_member_cookies_policy",
    name: "TvbMemberCookiePolicyTc",
    component: () =>
      import("@/views/Terms/TvbMemberCookiesPolicy/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "tc" },
  },
  {
    path: "e_tvb_member_cookies_policy",
    name: "TvbMemberCookiePolicyEn",
    component: () =>
      import("@/views/Terms/TvbMemberCookiesPolicy/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "en" },
  },
  {
    path: "tvb_member_function",
    name: "TvbMemberFunctionTc",
    component: () =>
      import("@/views/Terms/TvbMemberFunction/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "tc" },
  },
  {
    path: "e_tvb_member_function",
    name: "TvbMemberFunctionEn",
    component: () =>
      import("@/views/Terms/TvbMemberFunction/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "en" },
  },
  {
    path: "game.html",
    name: "GameTc",
    component: () =>
      import("@/views/Terms/Game/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "tc" },
  },
  {
    path: "e_game.html",
    name: "GameEn",
    component: () =>
      import("@/views/Terms/Game/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "en" },
  },
];
export default termsRouters;
