import axios from 'axios'
import axiosRetry from 'axios-retry'
import { version as appVersion } from '../../package.json'

const appName = import.meta.env.VITE_APP_NAME

const request = axios.create({
  timeout: 1000 * 10,
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

// 判断一段时间内的接口是否结束
let numberOfAjaxCAllPending = 0
const isAjaxCAllPendingEnd = () => {
  setTimeout(() => {
    numberOfAjaxCAllPending--
    if (numberOfAjaxCAllPending === 0 && !document.querySelector(`[name="${appName}"]`)) {
      const superUpdatesMeta = document.createElement('meta')
      superUpdatesMeta.name = appName
      superUpdatesMeta.content = 'true'
      document.querySelector('head').appendChild(superUpdatesMeta)
      document.getElementById('tvb-loading').style.display = 'none'
      document.getElementById('tvb').style.opacity = '1'
    }
  }, 500)
}

// 添加請求攔截器
request.interceptors.request.use(
  (config) => {
    numberOfAjaxCAllPending++
    if (localStorage.getItem('uuid')) config.headers['X-App-Client'] = `tvb-web/zh-HK/${appVersion}/${localStorage.getItem('uuid')}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 添加響應攔截器
request.interceptors.response.use(
  (response) => {
    isAjaxCAllPendingEnd()
    return Promise.resolve(response?.data || {})
  },
  (error) => {
    isAjaxCAllPendingEnd()
    return Promise.reject(error)
  }
)

// 重试处理
axiosRetry(request, {
  retries: 3,
  shouldResetTimeout: true,
  retryCondition: (error) => {
    return (
      axiosRetry.isNetworkOrIdempotentRequestError(error) || error?.message?.includes('timeout')
    )
  }
})


export default request
