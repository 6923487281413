import request from './../utils/request'

/**
 * 获取配置
 */
export const getConfig = () => {
  return request({
    url: '/config',
    method: 'get'
  })
}

/**
 * 获取 SEO 配置
 */
export const getSEO = () => {
  return request({
    url: '/seo',
    method: 'get'
  })
}

/**
 * 获取分享
 */
export const getShare = (params = {}) => {
  return request({
    url: '/article/share',
    method: 'get',
    params
  })
}

/**
 * 获取广告
 */
export const getAds = (params) => {
  return request({
    url: `/ads/${params.position}`,
    method: 'get',
    params
  })
}
