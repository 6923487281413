<script setup name="TvbRouterLink">
import { computed } from 'vue'
import { websites } from './../../constants/index'
const props = defineProps({
  to: {
    type: String,
    default: null
  },
  linkTarget: {
    type: Number,
    default: null
  },
  linkRel: {
    type: Number,
    default: null
  }
})

const componentIs = computed(() => {
  const { to } = props
  if (typeof to === 'string' && to) {
    if (to?.indexOf('/') === 0) {
      return 'router-link'
    } else {
      try {
        const url = new URL(to)
        return websites.includes(url.origin) ? 'router-link' : 'a'
      } catch (error) {
        console.error(error, to)
        return 'div'
      }
    }
  } else {
    return 'div'
  }
})

const componentBind = computed(() => {
  const bind = {}
  const { linkRel, linkTarget, to } = props
  if (linkRel === 1) {
    bind.rel = 'follow'
  } else if (linkRel === 2) {
    bind.rel = 'nofollow'
  }
  if (linkTarget === 2) {
    bind.target = '_blank'
  } else if (linkTarget === 1) {
    bind.target = '_self'
  }
  if (typeof to === 'string' && to) {
    if (to?.indexOf('/') === 0) {
      bind.to = to
    } else {
      try {
        const url = new URL(to)
        if (websites.includes(url.origin)) {
          bind.to = `${url.pathname}${url.search}`
        } else {
          bind.href = to
        }
      } catch (error) {
        console.error(error, to)
      }
    }
  }
  return bind
})
</script>

<template>
  <component :is="componentIs" v-bind="{ ...componentBind, ...$attrs }">
    <slot />
  </component>
</template>
