import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useCounterStore = defineStore('counter', () => {
  const sb = ref(1)
  const bn = ref(1)
  const lrec = ref(1)
  const reset = () => {
    sb.value = 1
    bn.value = 1
    lrec.value = 1
  }

  return { sb, bn, lrec, reset }
})
