const faqRouters = [
  {
    path: "index.html",
    name: "IndexTc",
    redirect: "/info/faq/corporation.html",
    component: () => import("@/views/Info/Faq/index.vue"),
    meta: { language: "tc" },
  },
  {
    path: "e_index.html",
    name: "IndexEn",
    redirect: "/info/faq/e_corporation.html",
    component: () => import("@/views/Info/Faq/index.vue"),
    meta: { language: "en" },
  },
  {
    path: "corporation.html",
    name: "CorporationTc",
    component: () =>
      import("@/views/Info/Faq/Corporation/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "tc" },
  },
  {
    path: "e_corporation.html",
    name: "CorporationEn",
    component: () =>
      import("@/views/Info/Faq/Corporation/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "en" },
  },
  {
    path: "artist.html",
    name: "ArtistTc",
    component: () =>
      import("@/views/Info/Faq/Artist/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "tc" },
  },
  {
    path: "e_artist.html",
    name: "ArtistEn",
    component: () =>
      import("@/views/Info/Faq/Artist/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "en" },
  },
  {
    path: "web_general.html",
    name: "WebGeneralTc",
    component: () =>
      import("@/views/Info/Faq/WebGeneral/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "tc" },
  },
  {
    path: "e_web_general.html",
    name: "WebGeneralEn",
    component: () =>
      import("@/views/Info/Faq/WebGeneral/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "en" },
  },
  {
    path: "misc.html",
    name: "MiscTc",
    component: () =>
      import("@/views/Info/Faq/Misc/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "tc" },
  },
  {
    path: "e_misc.html",
    name: "MiscEn",
    component: () =>
      import("@/views/Info/Faq/Misc/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "en" },
  },
  {
    path: "weekly.html",
    name: "WeeklyTc",
    component: () =>
      import("@/views/Info/Faq/Weekly/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "tc" },
  },
  {
    path: "e_weekly.html",
    name: "WeeklyEn",
    component: () =>
      import("@/views/Info/Faq/Weekly/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "en" },
  },
  {
    path: "overseas.html",
    name: "OverseasTc",
    component: () =>
      import("@/views/Info/Faq/Overseas/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "tc" },
  },
  {
    path: "e_overseas.html",
    name: "OverseasEn",
    component: () =>
      import("@/views/Info/Faq/Overseas/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "en" },
  },
  {
    path: "tvbonlineclub.html",
    name: "TvbOnlineClubTc",
    component: () =>
      import("@/views/Info/Faq/TvbOnlineClub/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "tc" },
  },
  {
    path: "e_tvbonlineclub.html",
    name: "TvbOnlineClubEn",
    component: () =>
      import("@/views/Info/Faq/TvbOnlineClub/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "en" },
  },
  {
    path: "sys_requirement.html",
    name: "SysRequirementTc",
    component: () =>
      import("@/views/Info/Faq/SysRequirement/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "tc" },
  },
  {
    path: "e_sys_requirement.html",
    name: "SysRequirementEn",
    component: () =>
      import("@/views/Info/Faq/SysRequirement/index.vue").catch(() =>
        window.location.reload()
      ),
    meta: { language: "en" },
  },
];
export default faqRouters;
