<script setup name="TvbBackTop">
import { inject, watch, ref } from 'vue'
import { useWindowScroll, useWindowSize } from '@vueuse/core'

const { x, y } = useWindowScroll()
const { width, height } = useWindowSize()
const size = inject('size')
const show = ref(false)
const bottom = ref(0)

watch([x, y, width, height], () => {
  show.value = height.value * 0.8 < y.value
  if (width.value > 786) {
    const footerClientRect = document.querySelector('.tvb-footer').getBoundingClientRect()
    const bottomDiff = height.value - footerClientRect.top
    if (bottomDiff > 0) {
      bottom.value = bottomDiff
    } else {
      bottom.value = 0
    }
  } else {
    bottom.value = 0
  }
})

const backTop = () => {
  window.scroll({ behavior: 'smooth', top: 0, left: 0 })
}
</script>

<template>
  <div
    :style="{ '--bottom-diff': `${bottom}px` }"
    class="tvb-back-top"
    @click="backTop"
    :class="{ mobile: ['s', 'xs'].includes(size), show: show }"
  >
    <tvb-svg-icon name="icon_top_grey" />
    <tvb-svg-icon name="icon_top_blue" />
  </div>
</template>

<style lang="scss" scoped>
.tvb-back-top {
  width: 0;
  height: 0;
  background: #f8f9fa;
  border-radius: 0;
  opacity: 0;
  border: 0 solid rgba(163, 178, 188, 0.35);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 40px;
  bottom: -40px;
  transition: background 0.15s linear;
  z-index: 99;
  svg {
    &:nth-child(2) {
      display: none;
    }
  }

  &.show {
    width: 48px;
    height: 48px;
    border: 0.5px solid rgba(163, 178, 188, 0.35);
    opacity: 1;
    right: 16px;
    bottom: calc(16px + var(--bottom-diff, 0px));
    border-radius: 8px;
  }

  &.mobile {
    right: 30px;
    bottom: -37px;
    &.show {
      width: 42px;
      height: 42px;
      border-radius: 6px;
      right: 8px;
      bottom: 12px;
    }
    svg {
      width: 24px;
      height: 24px;
      &:nth-child(2) {
        display: none;
      }
    }
  }

  &:active {
    opacity: 0.75;
  }

  svg {
    width: 28px;
    height: 28px;
  }

  &:hover {
    cursor: pointer;
    background: #ffffff;
    svg {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        display: block;
      }
    }
  }
}
</style>
