<script setup name="TvbLayout">
import { inject, computed, provide, watchEffect, ref } from 'vue'
import { TvbHeader, TvbFooter } from '@tvb-sz/tvb-headerfooter-frontend'
import { useRoute, useRouter } from 'vue-router'
import { websites } from './../constants/index'
import TvbBackTop from './../components/TvbBackTop/index.vue'
import { useSeoMeta } from '@vueuse/head'
import { action } from './../utils/gtm'

provide('seoTitleSuffix', ' | TVB 無綫電視')
const size = inject('size')
const seo = inject('seo')
const adParams = ref({})
const route = useRoute()
const router = useRouter()

provide('setAdParams', (params = {}) => {
  adParams.value = params
})
const preview = computed(() => route?.meta?.preview)
provide('isPreview', preview)

const bitClick = (e) => {
  switch (e.event_label) {
    case 'main-menu':
      action({
        page_title: 'main_menu',
        screen_class: e.title,
        screenID: 'S001',
        eventCategory: '',
        eventLabel: 'main_menu',
        eventID: e.id || 'B016',
        screenLabel: e.jump_url
      })
      if (e.jump_url) {
        try {
          const url = new URL(e.jump_url)
          if (websites.includes(url.origin)) {
            e.event.preventDefault()
            router.push(`${url.pathname}${url.search}`)
          }
        } catch (error) {
          console.error(error)
        }
      }
      break
    case 'main-sub-menu':
      action({
        page_title: 'main_menu',
        screen_class: e.title,
        screenID: 'S001',
        eventCategory: '',
        eventLabel: 'main_menu',
        eventID: e.id || 'B016',
        screenLabel: e.jump_url
      })
      if (e.jump_url) {
        try {
          const url = new URL(e.jump_url)
          if (websites.includes(url.origin)) {
            e.event.preventDefault()
            router.push(`${url.pathname}${url.search}`)
          }
        } catch (error) {
          console.error(error)
        }
      }
      break
    case 'logo':
      e.event.preventDefault()
      router.push('/')
      break
    case 'search-tag':
      e.event.preventDefault()
      if (e.id) {
        router.push(`/search?tag=${e.id}&tag_name=${e.name}`)
        action({
          page_title: 'search_result',
          screen_class: e.name,
          screenID: 'S001',
          eventCategory: '',
          eventLabel: 'search_result',
          eventID: 'B020',
          screenLabel: `${location.origin}/search?tag=${e.id}`
        })
      }
      break
    case 'search-keyword':
      e.event.preventDefault()
      if (e.keyword) {
        router.push(`/search?keyword=${e.keyword}`)
        action({
          page_title: 'search_result',
          screen_class: e.keyword,
          screenID: 'S001',
          eventCategory: '',
          eventLabel: 'search_result',
          eventID: 'B020',
          screenLabel: `${location.origin}/search?keyword=${e.keyword}`
        })
      }
      break
    case 'footer-menu':
      action({
        page_title: 'footer_menu',
        screen_class: e.title,
        screenID: 'S001',
        eventCategory: '',
        eventLabel: 'footer_menu',
        eventID: 'B018',
        screenLabel: e.jump_url
      })
      if (e.jump_url) {
        try {
          const url = new URL(e.jump_url)
          if (websites.includes(url.origin)) {
            e.event.preventDefault()
            router.push(`${url.pathname}${url.search}`)
          }
        } catch (error) {
          console.error(error)
        }
      }
      break
    default:
      break
  }
}

watchEffect(() => {
  const { og_image_url, og_image_alt_txt } = seo?.value || {}
  useSeoMeta({
    ogImage: `${og_image_url || ''}`,
    ogImageAlt: `${og_image_alt_txt || ''}`,
    twitterImage: `${og_image_url || ''}`,
    robots: preview.value || route?.meta?.noRobots ? 'noindex' : 'max-image-preview:large'
  })
})

// const position = computed(() => adsConfig?.[route?.name]?.position || '')

// const unit = computed(() => {
//   switch (route.name) {
//     case 'category':
//       return `/${route.params.categoryPath}`.replace('-c', '')
//     case 'detail':
//       return `/${route.params.articleId}`
//     case 'search':
//       return '/search'
//     default:
//       return ''
//   }
// })

const hidBackTop = computed(
  () =>
    ['detail', 'articlePreview', 'vote', 'votePreview'].includes(route.name) &&
    ['s', 'xs'].includes(size.value)
)

const layerData = computed(() => route?.meta?.layerData || {})
provide('layerData', layerData)
</script>

<template>
  <div class="tvb-container" :class="[size]">
    <header class="tvb-home-header" :class="[size]">
      <tvb-header @click="bitClick" :full-path="$route.fullPath" :key="['search', 'category', 'detail', 'articlePreview', 'vote', 'votePreview'].includes(
        $route.name
      )
          ? '1280px'
          : 'null'
        " :max-width="['search', 'category', 'detail', 'articlePreview', 'vote', 'votePreview'].includes(
          $route.name
        )
            ? '1280px'
            : null
          " />
    </header>
    <router-view class="tvb-main" :class="[size]" :key="$route.fullPath" />
    <footer class="tvb-home-footer">
      <tvb-footer @click="bitClick" :max-width="['search', 'category', 'detail', 'articlePreview', 'vote', 'votePreview'].includes(
        $route.name
      )
          ? '1280px'
          : null
        " />
    </footer>
    <tvb-back-top v-if="!hidBackTop" />
    <!-- <tvb-ads
      v-if="position"
      :position="position"
      :key="JSON.stringify(adParams || {})"
      :ad-type="'to'"
      :unit="unit"
      :params="adParams"
    /> -->
    <tvb-cookies />
  </div>
</template>

<style lang="scss" scoped>
.tvb-container {
  position: relative;

  .tvb-home-header {
    // height: 64px;
    background: #ffffff;
    // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 999;

    &.s,
    &.xs {
      height: 84px;
    }

    box-sizing: unset;

    :deep(.tvb-header) {
      .tvb-header-container {
        box-sizing: unset;

        .tvb-header-logo {
          display: flex;
          align-items: center;
        }

        .tvb-header-menu-text {
          margin-bottom: 0px !important;
        }
      }
    }
  }

  .tvb-main {
    margin: 0 auto;
    min-height: calc(var(--window-height, 100vh) - var(--header-height, 215px) - var(--footer-height, 64px));
    transition: all 500ms;
    max-width: calc(1712px + 56px * 2);
    padding: 20px 56px 100px;

    // &.s,
    // &.xs {
    //   min-height: calc(var(--window-height) - var(--header-height));
    // }
    &.xl {
      max-width: calc(1712px + 56px * 2);
      padding: 20px 56px 100px;

      @media (max-width: 1440px) {
        padding: 20px 56px 72px;
      }
    }

    &.l {
      max-width: calc(1712px + 40px * 2);
      padding: 20px 40px 72px;
    }

    &.m {
      max-width: calc(1712px + 32px * 2);
      padding: 20px 32px 56px;
    }

    &.s {
      max-width: calc(1712px + 16px * 2);
      padding: 16px 16px 32px;
    }

    &.xs {
      max-width: calc(1712px + 12px * 2);
      padding: 12px 12px 32px;
    }
  }

  .tvb-home-footer {
    :deep(.tvb-footer) {
      box-sizing: unset;

      .tvb-footer-copyright-follow .tvb-footer-follows .tvb-footer-follow {
        display: inline-block;
      }
    }
  }
}
</style>
