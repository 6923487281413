import './assets/style/main.css'
import './assets/style/animation.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createDiscreteApi } from 'naive-ui'
import { createHead } from '@vueuse/head'
import { useCounterStore } from './stores/counter'
import VueLazyload from 'vue-lazyload'

import App from './App.vue'
import router from './router'
import TvbSvgIcon from './components/TvbSvgIcon/index.vue'
import TvbImage from './components/TvbImage/index.vue'
import TvbButton from './components/TvbButton/index.vue'
import TvbRouterLink from './components/TvbRouterLink/index.vue'
import TvbAds from './components/TvbAds/index.vue'

import 'virtual:svg-icons-register'
import { useAppStore } from './stores/app'

const app = createApp(App)
const { loadingBar } = createDiscreteApi(['loadingBar'], {
  loadingBarProviderProps: {
    loadingBarStyle: {
      loading: 'background: #0062a3;height: 4px;'
    }
  }
})

app.use(createPinia())
app.use(router)
app.use(createHead())
app.use(VueLazyload, {
  error: '/image/img_header_logo@2x.png',
  loading: '/image/img_header_logo@2x.png'
})

app.component('tvb-svg-icon', TvbSvgIcon)
app.component('tvb-image', TvbImage)
app.component('tvb-button', TvbButton)
app.component('tvb-router-link', TvbRouterLink)
app.component('tvb-ads', TvbAds)

router.beforeEach(async (_, __, next) => {
  const counter = useCounterStore()
  const app = useAppStore()
  counter.reset()
  if (app) await app.fetchConfig()
  loadingBar.start()
  next()
})

router.afterEach((to) => {
  if (to.name.includes('error')) {
    loadingBar.error()
  } else {
    loadingBar.finish()
  }
})

app.mount('#tvb')
