import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getConfig } from '@/api/app'

export const useAppStore = defineStore('app', () => {
  const config = ref(null)

  const fetchConfig = async () => {
    try {
      const { code, data, message } = await getConfig()
      if (code === 0) {
        if (!data?.uuid) data.uuid = window.uuidv4()
        config.value = data
      } else throw Error({ code, data, message })
    } catch (error) {
      console.error(error)
      config.value = { uuid: window.uuidv4() }
    }
    localStorage.setItem('uuid', config.value?.uuid || window.uuidv4())
    return config.value
  }

  return { config, fetchConfig }
})
