<script setup name="TvbAds">
import { reactive, nextTick, ref, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import sha256 from 'crypto-js/sha256'
import { generateMixed } from './../../utils/index'
import { getAds } from './../../api/app'
import { adsConfig } from './../../constants/index'
import { useCounterStore } from './../../stores/counter'

const emits = defineEmits(['load', 'error'])

const props = defineProps({
  /**
   * 广告id 自定义且唯一
   */
  id: {
    type: String,
    default: () => generateMixed(16)
  },
  /**
   * 广告类型 bn(banner)/lrec
   * "superbanner1" for the 1st Super Banner of the page. "superbanner2" for the 2nd Super Banner of the page, etc. Footer Super Banner is hardcoded to be "superbanner99"
   * "lrec1" for the 1st LREC. "lrec2" for the 2nd LREC. etc.
   * "takeover1" for the 1st Outstream Ads
   */
  adType: {
    type: String,
    required: true
  },
  /**
   * 位置
   * 1-首頁，2-featured，3-每集內容，4-節目表總覽，5-Channel
   * 具体见 https://tvbco-my.sharepoint.com/:p:/g/personal/wing_do_tvb_com/ERjfPxk80VJOlfXFbuIrKZIB3eW5iUylAM-paLuhr7wW7g?e=wVk7YL
   */
  position: {
    type: Number,
    required: true
  },
  /** 后缀 */
  unit: {
    type: String,
    default: ''
  },
  /**
   * 广告其他参数
   * 具体见 https://tvbcom.atlassian.net/browse/TC-636
   */
  params: {
    type: Object,
    default: () => {}
  }
})

const route = useRoute()
const tvbAdsRef = ref(null)
const counter = useCounterStore()

const ads = reactive({
  id: `TVBADS${props.id}`,
  loading: true,
  show: false,
  unitPrefix: '',
  list: [],
  type: '',
  refresh: 0,
  size: [],
  interval: null,
  io: null,
  isEmpty: false
})

/** 初始化广告 */
const initAds = () => {
  const config = adsConfig[route.name]
  if (config) {
    ads.unitPrefix += config.unit + props.unit + '/' + props.adType
    for (let index = 0; index < ads.list.length; index++) {
      const { height, width, size_setting } = ads.list[index]
      const [minHeight, maxHeight] = height
      const [minWidth, maxWidth] = width
      const { innerHeight, innerWidth } = window
      if (
        innerWidth >= (minWidth || -Infinity) &&
        innerWidth <= (maxWidth || Infinity) &&
        innerHeight >= (minHeight || -Infinity) &&
        innerHeight <= (maxHeight || Infinity)
      ) {
        for (let indexs = 0; indexs < size_setting.length; indexs++) {
          const { ad_type, refresh, size } = size_setting[indexs]
          if (ad_type === props.adType) {
            ads.refresh = refresh
            ads.size = size
            switch (props.adType) {
              case 'to':
                ads.type = 'takeover1'
                break
              case 'sb':
                ads.type = `superbanner${counter.sb}`
                counter.sb++
                break
              case 'bn':
                ads.type = `banner${counter.bn}`
                counter.bn++
                break
              case 'lrec':
                ads.type = `lrec${counter.lrec}`
                counter.lrec++
                break
              case 'os':
                ads.type = 'os'
                break
              default:
                break
            }
            nextTick(() => pushAds())
          }
        }
      }
    }
  }

  ads.isEmpty = !ads.type
}

/** 添加广告 */
const pushAds = (googletag = window.googletag) => {
  googletag.cmd.push(function () {
    googletag.pubads().enableSingleRequest()
    googletag.enableServices()
    googletag.pubads().setCentering(true)
    const slot = googletag
      .defineSlot(ads.unitPrefix, ads.size, ads.id)
      .addService(googletag.pubads())
    slot.addService(googletag.pubads())
    googletag.pubads()?.clearTargeting?.()
    slot?.clearTargeting?.()
    const params = JSON.parse(JSON.stringify(props?.params || {}))
    params.adtype = ads.type
    params.hdid = sha256(localStorage.uuid).toString()
    if (route.name === 'HomePage') params.subdomain = 'www=https://www.tvb.com/*'
    if (!props?.params?.vtype) params.vtype = 'x'
    if (Object.keys(params || {}).length) {
      for (const key in params) {
        googletag?.pubads()?.setTargeting(key, `${params[key]}`)
      }
    }

    slot.setTargeting('adtype', params.adtype)
    googletag.display(ads.id)
    googletag.pubads().addEventListener('slotRenderEnded', (event) => {
      if (slot === event.slot) {
        event.params = params
        console.group(
          `Ad「${ads.type}」unit「${
            ads.unitPrefix
          }」slot「${slot.getSlotElementId()}」finished rendering.`
        )
        console.log('所呈现广告的广告客户 ID (Advertiser ID):', event.advertiserId)
        console.log('所呈现广告的广告系列 ID (Campaign ID):', event.campaignId)
        console.log('对所呈现的补余广告出价的公司的 ID (Company IDs):', event.companyIds)
        console.log('已呈现的预订型广告的广告素材 ID (Creative ID):', event.creativeId)
        console.log(
          '所呈现的预订型广告的广告素材模板 ID (Creative Template ID):',
          event.creativeTemplateId
        )
        console.log('广告是否为补余广告 (Is backfill)?:', event.isBackfill)
        console.log('是否针对广告位返回了空广告 (Is empty)?:', event.isEmpty)
        console.log('所呈现广告的标签 ID (Label IDs):', event.labelIds)
        console.log('已呈现的预订型广告的订单项 ID (Line Item ID):', event.lineItemId)
        console.log('所呈现广告素材的像素尺寸 (Size):', event.size)
        console.log(
          '广告位内容是否随呈现的广告而发生更改 (Slot content changed)?',
          event.slotContentChanged
        )
        console.log(
          '已呈现的预订型广告或补余广告的广告素材 ID (Source Agnostic Creative ID):',
          event.sourceAgnosticCreativeId
        )
        console.log(
          '已呈现的预订型广告或补余广告的订单项 ID (Source Agnostic Line Item ID):',
          event.sourceAgnosticLineItemId
        )
        console.log('所呈现的补余广告的收益组的 ID (Yield Group IDs):', event.yieldGroupIds)
        console.log('所呈现广告的参数:', event.params)
        console.groupEnd()
        ads.isEmpty = event.isEmpty
        if (!event.isEmpty) emits('load', event)
        else emits('error', event)
        ads.loading = false
      }
    })
    // 设置刷新
    if (ads.refresh) {
      try {
        ads.io = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) {
            if (ads.interval) {
              clearInterval(ads.interval)
            }
            ads.interval = setInterval(() => {
              googletag.pubads().refresh([slot])
            }, ads.refresh * 1000)
          } else if (ads.interval) {
            clearInterval(ads.interval)
          }
        })
        if (tvbAdsRef.value) {
          ads.io.observe(tvbAdsRef.value)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }
  })
}

/** 获取广告参数 */
getAds({ position: props.position, adtype: props.adType }).then(
  ({ data: { ad_unit_prefix, ads_list } }) => {
    ads.unitPrefix = ad_unit_prefix
    ads.list = ads_list
    initAds()
  }
)

onBeforeUnmount(() => {
  if (ads.interval) {
    clearInterval(ads.interval)
  }
  ads?.io?.disconnect()
})
</script>

<template>
  <div
    v-show="!ads.isEmpty"
    class="tvb-ads"
    ref="tvbAdsRef"
    :data-type="ads.type || adType"
    :class="{ zero: ads.loading || ads.isEmpty }"
    :style="{
      display: ads.isEmpty ? 'none' : null,
      // marginTop: ads.loading || ads.isEmpty ? '0px !important' : null,
      // margin: ads.loading || ads.isEmpty ? '0px !important' : null,
      // padding: ads.loading || ads.isEmpty ? '0px !important' : null,
      // height: ads.loading || ads.isEmpty ? '0px !important' : null,
      // minHeight: ads?.size?.[0]?.[1] ? `${ads.size?.[0]?.[1]}px` : null,
      background: ads.isEmpty || ads.loading ? 'rgba(240, 241, 242,0)' : 'rgb(240, 241, 242, 0)'
    }"
  >
    <div class="tvb-ads_container" :id="ads.id"></div>
    <div
      v-if="ads.isEmpty || ads.loading"
      class="tvb-ads_container-empty"
      :title="ads.type || adType"
    >
      &nbsp;
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tvb-ads {
  width: 100%;
  height: auto;
  position: relative;
  background: rgb(240, 241, 242);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  &.zero {
    margin: 0px !important;
    padding: 0px !important;
    height: 0px !important;
  }

  .tvb-ads_container {
    margin: 0 auto;
    width: inherit;
    height: 100%;
    overflow: hidden;
    display: flex;
  }

  .tvb-ads_container-empty {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 8px;
    line-height: 28px;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    user-select: none;
  }
}
</style>
