<template>
  <div class="tvb-cookies" :class="{ show: showCookies }">
    <div class="tvb-cookies_room">
      <div class="tvb-cookies_content">
        <h3 class="tvb-cookies_content-title">
          <tvb-svg-icon name="icon_cookies" class="tvb-cookies_content-cookies" />
          本服務使用 Cookies
        </h3>
        <p class="tvb-cookies_content-description">
          本服務採用 Cookies 以保持其可靠性，促致個人化內容和廣告。 請按「同意」代表閣下同意我們的
          <router-link to="/terms/tvb_member_cookies_policy/"> Cookies 政策 </router-link>
          以及
          <router-link to="/terms/tvb_member_pics/"> 個人資料收集聲明 </router-link>
          。
        </p>
      </div>
      <button class="tvb-cookies_agree" @click="showCookies = false"><span>同意</span></button>
    </div>
  </div>
</template>

<script setup name="TvbCookies">
import { ref, watch } from 'vue'
import { setCookie, getCookie } from '@tvb-sz/tvb-headerfooter-frontend/es/utils/cookies'

const showCookies = ref(!getCookie('_cookie_policy'))

watch(showCookies, (showCookies) => {
  if (!showCookies) setCookie('_cookie_policy', 'true', 99999)
})
</script>

<style lang="scss" scoped>
.tvb-cookies {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 99998;
  bottom: -300px;
  transition: bottom 0.3s linear;
  margin: 0 auto;
  max-width: 1280px;
  width: calc(100% - 16px * 2);
  min-width: calc(320px - 12px * 2);

  &.show {
    bottom: 16px;
  }

  @media (max-width: 767px) {
    width: calc(100% - 12px * 2);

    &.show {
      bottom: 12px;
    }
  }

  .tvb-cookies_room {
    background: rgba(5, 5, 6, 0.9);
    border-radius: 8px;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
      border-radius: 6px;
      flex-direction: column;
      padding: 16px;
    }

    .tvb-cookies_content {
      .tvb-cookies_content-title {
        font-weight: 500;
        font-size: 20px;
        color: #eeeeee;
        line-height: 26px;
        text-align: left;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;

        .tvb-cookies_content-cookies {
          font-size: 24px;
          margin-right: 6px;
        }

        @media (max-width: 767px) {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;

          .tvb-cookies_content-cookies {
            font-size: 20px;
            margin-right: 5px;
          }
        }
      }

      .tvb-cookies_content-description {
        font-weight: 400;
        font-size: 16px;
        color: #dddddd;
        line-height: 26px;
        text-align: left;
        margin-top: 4px;
        letter-spacing: 0.5px;

        a {
          color: rgba(54, 162, 233, 1);
        }

        @media (max-width: 767px) {
          margin-top: 9px;
          font-size: 14px;
          line-height: 20px;
          word-break: break-all;
          text-align: justify;
          letter-spacing: 0.3px;

          a {
            text-decoration: underline;
          }
        }
      }
    }

    .tvb-cookies_agree {
      width: 128px;
      min-width: 128px;
      height: 40px;
      background: #0062a3;
      border-radius: 6px;
      font-weight: 400;
      font-size: 16px;
      color: #eeeeee;
      line-height: 24px;
      border: 0;
      outline: 0;
      cursor: pointer;
      margin-left: 32px;
      overflow: hidden;
      position: relative;
      letter-spacing: 0.5px;

      &::after {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        content: ' ';
        display: block;
        background: #000000;
        border-radius: 6px;
        opacity: 0;
        transition: opacity 0.15s linear;
      }

      @media (min-width: 768px) {
        letter-spacing: 0.3px;
        &:hover::after {
          opacity: 0.2;
        }
      }

      span {
        position: relative;
        z-index: 9;
      }

      @media (max-width: 767px) {
        margin-top: 16px;
        width: 108px;
        min-width: 108px;
        height: 32px;
        font-size: 14px;
        line-height: 20px;
        margin-left: 0;
      }
    }
  }
}
</style>
